<script setup lang="ts">
import { vClickOutside } from '~/directives/ClickOutside'

import { hasSlotContent } from '~/utils/hasSlot'

import type HtmlTag from '~/types/htmlTag'
import type { StyleRecord } from '~/types/styleRecord'

interface TooltipSize {
  width: number
  height: number
}

/**
 * @prop htmlTag - The html tag of the element wrapping the element that triggers the tooltip. Defaults to 'span'.
 * @prop appendTo - The query selector of the element to append the tooltip to. Defaults to 'body'.
 * @prop position - Sets the preferred position of the tooltip in relation to its anchor element. Note that this can be overridden if there's not enough space. Defaults to 'below'.
 * @prop forceToShow - If the tooltip should be shown by default without needing to hover it first. Defaults to false.
 * @prop forceToHide - If the tooltip should be hidden even if it's triggered. Defaults to false.
 * @prop showWhenClicked - If the tooltip should show when the anchor element is clicked. Defaults to false.
 * @prop parentScrollElQuery - The query selector of the parent element that scrolls. This is used to keep the tooltip positioned correctly when the parent element is scrolled. Defaults to 'body'.
 * @prop paddingClass - Optional classes to set another padding.
 * @prop hideTooltipArrow - Optional property to toggle arrow visibility. Defaults to false.
 */
type Props = {
  htmlTag?: HtmlTag
  appendTo?: string
  position?: 'above' | 'below'
  forceToShow?: boolean
  forceToHide?: boolean
  showWhenClicked?: boolean
  parentScrollElQuery?: string
  paddingClass?: string
  hideTooltipArrow?: boolean
}

type Emits = {
  show: []
  hide: []
}

type Slots = {
  default(): any
  tooltipText(): any
  cta(): any
}

const props = withDefaults(defineProps<Props>(), {
  htmlTag: 'span',
  appendTo: 'body',
  position: 'below',
  forceToShow: false,
  forceToHide: false,
  showWhenClicked: false,
  parentScrollElQuery: '',
  paddingClass: 'tw-p-4',
  hideTooltipArrow: false,
})
const emit = defineEmits<Emits>()
const slots = defineSlots<Slots>()

const TOOLTIP_OFFSET = 16
const NAV_BAR_HEIGHT = 80

const rootElement = ref<HTMLElement | null>(null)
const isDisplayed = ref(false)
const displayPosition = ref(props.position)
const tooltipPosStyle = ref<StyleRecord<'top' | 'left'>>({
  top: '0px',
  left: '0px',
})
const tooltipSize = ref<TooltipSize>({
  width: 0,
  height: 0,
})
const tooltipRef = ref<HTMLElement | null>(null)
const tooltipArrowStyle = ref<StyleRecord<'left'>>({
  left: '0px',
})
const tooltipArrowRef = ref<HTMLElement | null>(null)
const isScrolling = ref(false)
const isResizing = ref(false)
const isDisplayedViaClick = ref(false)
const anchorObserver = ref<IntersectionObserver | null>(null)
const uuid = useId()

watch(
  () => props.forceToShow,
  (newVal) => {
    if (!newVal) {
      isDisplayed.value = false
      emit('hide')
    }
  },
)
watch(isDisplayed, (newVal) => {
  if (newVal) {
    appendTooltipToDOM()
    emit('show')
  } else {
    emit('hide')
  }
})

onMounted(() => {
  setScrollAndResizeHandlers()
})

onBeforeUnmount(() => {
  isDisplayed.value = false
  isDisplayedViaClick.value = false
  emit('hide')

  const appendToEl = document.querySelector(props.appendTo)
  if (appendToEl) {
    if (tooltipRef.value)
      appendToEl.removeChild(tooltipRef.value as HTMLElement)
  }
})

onUnmounted(() => () => {
  window.removeEventListener('resize', handleWindowResize)
  getScrollEl().removeEventListener('scroll', handleParentScroll)

  if (anchorObserver.value && rootElement.value)
    anchorObserver.value.unobserve(rootElement.value)
})

function handleClick() {
  if (!props.showWhenClicked || props.forceToHide) return

  // prevents the tooltip from closing when clicking on the anchor since it will be shown via hover already
  if (!isDisplayedViaClick.value) {
    isDisplayed.value = true
    isDisplayedViaClick.value = true
    // make sure tooltip element has focus
    tooltipRef.value?.focus()
    return
  }

  isDisplayed.value = !isDisplayed.value
  isDisplayedViaClick.value = isDisplayed.value
}

function handleClickOutside(e: PointerEvent | TouchEvent) {
  if (!isDisplayed.value) return

  const clickedElement = e.target as Element

  /*
   * Prevent closing the tooltip when clicking inside the tooltip display.
   * This helps to avoid scenarios where clicking buttons/links inside the tooltip display
   * would instead close the tooltip, and fire off the click event of whatever the tooltip was covering.
   */
  if (tooltipRef.value && tooltipRef.value.contains(clickedElement)) return

  isDisplayed.value = false
  isDisplayedViaClick.value = false
}

function handleEscapePressed() {
  isDisplayed.value = false
  isDisplayedViaClick.value = false
}

function handleMouseEnter() {
  if (!props.forceToShow && !isDisplayed.value && !props.forceToHide)
    isDisplayed.value = true
}

function handleMouseLeave(event: MouseEvent) {
  if (!props.forceToShow && isDisplayed.value && !isDisplayedViaClick.value) {
    const mouseTraveledToEl = event.relatedTarget as Element | null
    // prevent flickering when mouse is touching the tooltip instead of the element that triggers it
    if (
      !!rootElement.value &&
      isTooltipOrRelatedElement(mouseTraveledToEl, rootElement.value)
    )
      return

    isDisplayed.value = false
  }
}

function handleParentScroll() {
  if (!isScrolling.value) {
    window.requestAnimationFrame(() => {
      if (isDisplayed.value) appendTooltipToDOM()

      isScrolling.value = false
    })
  }

  isScrolling.value = true
}

function handleWindowResize() {
  if (!isResizing.value) {
    window.requestAnimationFrame(() => {
      if (isDisplayed.value) appendTooltipToDOM()

      isResizing.value = false
    })
  }

  isResizing.value = true
}

async function appendTooltipToDOM() {
  // $nextTick guarantees child components are mounted
  await nextTick()
  if (!rootElement.value || !isDisplayed.value) return

  const anchorEl = rootElement.value.firstElementChild as HTMLElement
  const {
    top,
    left,
    bottom,
    width: anchorWidth,
  } = anchorEl
    ? anchorEl.getBoundingClientRect()
    : { top: 0, left: 0, bottom: 0, width: 0 }

  if (tooltipRef.value) {
    // always reset height since some tooltips start with a cta and then get resized after it's clicked
    tooltipSize.value.height = tooltipRef.value.offsetHeight

    // only attach the tooltip element the first time, the tooltip's width will be 0 until it's attached
    if (tooltipSize.value.width === 0) {
      tooltipSize.value.width = tooltipRef.value.offsetWidth

      const appendToEl = document.querySelector(props.appendTo)
      if (appendToEl) appendToEl.appendChild(tooltipRef.value)
    }
  }
  setTooltipPos(top, left, bottom, anchorWidth ?? 0)
  setTooltipArrowPos(
    left,
    anchorWidth ?? 0,
    parsePxValue(tooltipPosStyle.value.left),
  )
  // make sure tooltip element has focus
  tooltipRef.value?.focus({ preventScroll: true })
}

function getTopPosForPlacementAbove(
  anchorElTopPos: number,
  tooltipHeight: number,
  domScrollTop: number,
) {
  const tooltipHeightWithOffset = tooltipHeight + TOOLTIP_OFFSET
  return `${anchorElTopPos - tooltipHeightWithOffset + domScrollTop}px` as const
}

function getTopPosForPlacementBelow(
  anchorElBottomPos: number,
  domScrollTop: number,
) {
  return `${anchorElBottomPos + TOOLTIP_OFFSET + domScrollTop}px` as const
}

function isTooltipOrRelatedElement(
  element: Element | null,
  tooltipEl: Element,
): boolean {
  if (!element) return false

  return (
    element.classList.contains('tooltipArrow') ||
    element.classList.contains('tooltipContainer') ||
    tooltipEl.contains(element) ||
    element === tooltipEl
  )
}

/**
 * Set up scroll and page resize handling with an intersection observer
 */
function setScrollAndResizeHandlers() {
  const options = {
    threshold: 1.0,
  }

  anchorObserver.value = new IntersectionObserver(
    (entries: IntersectionObserverEntry[]) => {
      if (entries[0].isIntersecting === true) {
        isDisplayed.value = props.forceToShow

        window.addEventListener('resize', handleWindowResize)
        getScrollEl().addEventListener('scroll', handleParentScroll)

        appendTooltipToDOM()
      } else {
        isDisplayed.value = false
        isDisplayedViaClick.value = false
      }
    },
    options,
  )

  if (rootElement.value) anchorObserver.value.observe(rootElement.value)
}

/**
 * Get the scroll element to attach scroll event listener to.
 * @returns the element provided by the parentScrollQuery prop if it exists, otherwise the window itself.
 */
function getScrollEl() {
  // use "null" if there's no parentScrollQuery prop so it'll default to window and not cause an error when querySelector tries to select empty strings
  return document.querySelector(props.parentScrollElQuery || 'null') ?? window
}

function setTooltipPos(
  anchorTop: number,
  anchorLeft: number,
  anchorBottom: number,
  anchorWidth: number,
) {
  const domScrollTop = document.documentElement.scrollTop
  const halfWidth = tooltipSize.value.width / 2 - anchorWidth / 2
  let leftPos = `${anchorLeft - halfWidth}px`
  let topPos = tooltipPosStyle.value.top

  const bodyEl = document.body
  let viewPortHeight = 0
  let viewPortWidth = 0

  if (bodyEl) {
    const bodyClientRect = bodyEl.getBoundingClientRect()
    viewPortHeight = bodyClientRect.height ?? 0
    viewPortWidth = bodyClientRect.width ?? 0
  }

  viewPortHeight = Math.min(window.innerHeight, viewPortHeight)

  // always start with the preferred position of the tooltip
  displayPosition.value = props.position

  if (displayPosition.value === 'below') {
    // if the tooltip is too large to display below, put it above
    if (
      anchorBottom + tooltipSize.value.height + TOOLTIP_OFFSET >
      viewPortHeight
    ) {
      displayPosition.value = 'above'
      topPos = getTopPosForPlacementAbove(
        anchorTop,
        tooltipSize.value.height,
        domScrollTop,
      )
    } else {
      topPos = getTopPosForPlacementBelow(anchorBottom, domScrollTop)
    }
  } else if (displayPosition.value === 'above') {
    // if the tooltip is too large to display above, put it below
    if (
      anchorTop - NAV_BAR_HEIGHT - (tooltipSize.value.height + TOOLTIP_OFFSET) <
      0
    ) {
      displayPosition.value = 'below'
      topPos = getTopPosForPlacementBelow(anchorBottom, domScrollTop)
    } else {
      topPos = getTopPosForPlacementAbove(
        anchorTop,
        tooltipSize.value.height,
        domScrollTop,
      )
    }
  }

  const SIDE_OF_SCREEN_OFFSET = 2

  // left side of tooltip is off screen
  if (
    (tooltipRef.value && tooltipRef.value.offsetLeft < 0) ||
    parsePxValue(leftPos) < 0
  )
    leftPos = `${SIDE_OF_SCREEN_OFFSET}px`
  // right side of tooltip is off screen
  else if (
    (tooltipRef.value &&
      tooltipRef.value.offsetLeft + tooltipSize.value.width > viewPortWidth) ||
    parsePxValue(leftPos) + tooltipSize.value.width > viewPortWidth
  )
    leftPos = `${viewPortWidth - tooltipSize.value.width - SIDE_OF_SCREEN_OFFSET}px`

  tooltipPosStyle.value = {
    top: topPos,
    left: leftPos,
  }
}

function setTooltipArrowPos(
  anchorLeft: number,
  anchorWidth: number,
  tooltipLeft: number,
) {
  const halfAnchorWidth = anchorWidth / 2
  const halfArrowWidth =
    (tooltipArrowRef.value && tooltipArrowRef.value.offsetWidth / 2) ?? 0
  const anchorLeftPosAlongTooltip = anchorLeft - tooltipLeft
  const leftPos = `${anchorLeftPosAlongTooltip + halfAnchorWidth - halfArrowWidth}px`

  tooltipArrowStyle.value = {
    left: leftPos,
  }
}

function parsePxValue(value: string) {
  return Number(value.replace('px', '')) ?? 0
}
</script>

<script lang="ts">
/**
 * Displays a tooltip with the given `#tooltipText` and optional `#cta` ("call to action") slots.
 * The VTooltip container element is what triggers visibility of the tooltip when hovered.
 *
 * The tooltip itself gets detached from the containing element and is
 * reattached to the element found by the `appendTo` prop (body by default).
 * This helps to ensure that the tooltip will always appear above other elements.
 *
 * Note that although hovering is the default behavior to show/hide the tooltip,
 * the tooltip can be made to act more like a dismissable popup by setting `forceToShow=true`
 * and using another action to dismiss it such as a button in the `#cta` slot.
 *
 * @example
 * ```html
 * <VTooltip>
 *   <template #default><i class="fa-solid fa-circle-info"/></template>
 *   <template #tooltipText>I'm tooltip text!</template>
 * </VTooltip>
 *
 * <VTooltip
 *   html-tag="button"
 *   :force-to-show="true"
 *   append-to="div.someClass"
 *   parent-scroll-el-query=".someScrollingContainerClass"
 *   position="above"
 *   :hideTooltipArrow="true"
 * >
 *   <template #default>Button text here</template>
 *   <template #tooltipText>Tooltip text describing what the button does</template>
 *   <template #cta><button @click="closeTooltip">Dismiss this tooltip</button></template>
 * </VTooltip>
 * ```
 */
export default {}
</script>

<template>
  <component
    :is="htmlTag"
    ref="rootElement"
    v-click-outside="handleClickOutside"
    :aria-describedby="uuid"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
    @click.stop="handleClick"
    @keydown.escape="handleEscapePressed"
  >
    <slot name="default" />
    <transition name="fade" mode="out-in">
      <div
        v-if="isDisplayed"
        :id="uuid"
        ref="tooltipRef"
        tabindex="-1"
        role="tooltip"
        class="tooltipContainer tw-absolute tw-z-[999999] tw-flex tw-w-max tw-flex-col tw-items-center tw-justify-center tw-rounded-xs tw-bg-black tw-text-center tw-text-white focus:tw-outline-none"
        :class="{
          'emptyHoverAreaBottom tw-shadow-stickyTop':
            displayPosition === 'above',
          'emptyHoverAreaTop tw-shadow-stickyBottom':
            displayPosition === 'below',
          [paddingClass]: true,
          hideTooltipArrow,
          '-tw-translate-y-2': hideTooltipArrow && displayPosition === 'below',
          'tw-translate-y-2': hideTooltipArrow && displayPosition === 'above',
        }"
        :style="tooltipPosStyle"
        @mouseleave="handleMouseLeave"
        @keydown.escape="handleEscapePressed"
      >
        <VText cfg="sans/14/normal" color="white" html-tag="p">
          <slot name="tooltipText" />
        </VText>
        <div v-if="hasSlotContent(slots.cta)">
          <slot name="cta" />
        </div>
        <span
          v-if="!hideTooltipArrow"
          ref="tooltipArrowRef"
          class="tooltipArrow tw-absolute"
          :style="tooltipArrowStyle"
          :class="{
            attachBelow: displayPosition === 'above',
            attachAbove: displayPosition === 'below',
          }"
          data-test-id="tooltipArrow"
        />
      </div>
    </transition>
  </component>
</template>

<style lang="scss" scoped>
.tooltipContainer {
  max-width: calc(min(90vw, 15rem));

  &.emptyHoverAreaTop::before {
    @apply tw-absolute -tw-top-4 tw-left-0 tw-h-4 tw-w-full tw-bg-transparent;
    content: '';

    &.hideTooltipArrow {
      @apply -tw-top-2 tw-h-2;
    }
  }

  &.emptyHoverAreaBottom::after {
    @apply tw-absolute -tw-bottom-4 tw-left-0 tw-h-4 tw-w-full tw-bg-transparent;
    content: '';

    &.hideTooltipArrow {
      @apply -tw-bottom-2 tw-h-2;
    }
  }
}

.tooltipArrow {
  --arrow-offset: 0.667;
  --arrow-height: 16px;
  --arrow-width: calc(var(--arrow-height) * var(--arrow-offset));

  width: 0;
  height: 0;
  border-left: var(--arrow-width) solid transparent;
  border-right: var(--arrow-width) solid transparent;

  &.attachAbove {
    top: calc(var(--arrow-width) * -1);
    border-bottom: var(--arrow-height) solid theme('colors.black');
  }

  &.attachBelow {
    bottom: calc(var(--arrow-width) * -1);
    border-top: var(--arrow-height) solid theme('colors.black');
  }
}
</style>
